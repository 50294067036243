import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { productApi } from '../../../api';
import routes from '../../../config/routes';
import { getLocalStorage, setLocalStorage } from '../../../utils/localStorage';

export const ChooseOption = () => {
  const navigate = useNavigate();
  const [activeTag, setActiveTag] = useState<any>({});
  const [listAllTag, setListAllTag] = useState<any>({});
  const [clickTag, setClickTag] = useState<boolean>(true);
  const activeTagLocal: any = getLocalStorage('activeTag');

  const handleAcitveTags = (name_tag: string, id_tag: number) => {
    const temp = {
      ...activeTag,
      [name_tag]: id_tag,
    };
    setActiveTag({ ...temp });
    setClickTag(!clickTag);
  };

  // Get Product By Options
  const handleGetProduct = async () => {
    const res: any = await productApi.getProductWithTags(activeTag);

    try {
      if (res.status === 'success') {
        setLocalStorage('activeTag', {
          ...activeTag,
        });
        setLocalStorage('productTag', res.data);
        navigate(routes.chooseProduct);
      }
    } catch (err) {
      console.log('保存に失敗しました。');
    }
  };

  useEffect(() => {
    if (activeTagLocal) {
      setActiveTag(activeTagLocal);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      activeTag.styleTag &&
      activeTag.playTag &&
      activeTag.preferTag &&
      activeTag.positionTag &&
      activeTag.sizeTag &&
      activeTag.throwTag
    ) {
      handleGetProduct();
    }
    // eslint-disable-next-line
  }, [clickTag]);

  // Get Data in Local Storage
  useEffect(() => {
    async function getDataSettingAll() {
      const resTagStyle: any = await productApi.getAllTags();
      setListAllTag(resTagStyle.data);
    }
    getDataSettingAll();
    // eslint-disable-next-line
  }, []);

  return (
    <section className="main screen_option">
      <div className="container">
        <div className="txt_option">
          <div className="block_option">
            <h1>What style do you prefer?</h1>
            <div className="block_option_detail">
              {listAllTag?.product_tag_style?.map((tag: any) => (
                <div className="option_detail" key={tag.id}>
                  <span
                    className={`text-tag ${
                      tag.id === (activeTag?.styleTag || activeTagLocal?.styleTag) ? 'active' : ''
                    }`}
                    onClick={() => {
                      handleAcitveTags('styleTag', tag.id);
                    }}
                  >
                    {tag.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="block_option">
            <h1>What sport do you play?</h1>
            <div className="block_option_detail">
              {listAllTag?.product_tag_play?.map((tag: any) => (
                <div className="option_detail" key={tag.id}>
                  <span
                    className={`text-tag ${tag.id === (activeTag?.playTag || activeTagLocal?.playTag) ? 'active' : ''}`}
                    onClick={() => {
                      handleAcitveTags('playTag', tag.id);
                    }}
                  >
                    {tag.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="block_option">
            <h1>What leather would you prefer?</h1>
            <div className="block_option_detail">
              {listAllTag?.product_tag_prefer?.map((tag: any) => (
                <div className="option_detail" key={tag.id}>
                  <span
                    className={`text-tag ${
                      tag.id === (activeTag?.preferTag || activeTagLocal?.preferTag) ? 'active' : ''
                    }`}
                    onClick={() => {
                      handleAcitveTags('preferTag', tag.id);
                    }}
                  >
                    {tag.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="block_option">
            <h1>What position do you play?</h1>
            <div className="block_option_detail one-grid">
              {listAllTag?.product_tag_position?.map((tag: any) => (
                <div className="option_detail" key={tag.id}>
                  <span
                    className={`text-tag ${
                      tag.id === (activeTag?.positionTag || activeTagLocal?.positionTag) ? 'active' : ''
                    }`}
                    onClick={() => {
                      handleAcitveTags('positionTag', tag.id);
                    }}
                  >
                    {tag.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="block_option">
            <h1>What Player would you like?</h1>
            <div className="block_option_detail">
              {listAllTag?.product_tag_size?.map((tag: any) => (
                <div className="option_detail" key={tag.id}>
                  <span
                    className={`text-tag ${tag.id === (activeTag?.sizeTag || activeTagLocal?.sizeTag) ? 'active' : ''}`}
                    onClick={() => {
                      handleAcitveTags('sizeTag', tag.id);
                    }}
                  >
                    {tag.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="block_option">
            <h1>Which hand do you throw with?</h1>
            <div className="block_option_detail">
              {listAllTag?.product_tag_throw?.map((tag: any) => (
                <div className="option_detail" key={tag.id}>
                  <span
                    className={`text-tag ${
                      tag.id === (activeTag?.throwTag || activeTagLocal?.throwTag) ? 'active' : ''
                    }`}
                    onClick={() => {
                      handleAcitveTags('throwTag', tag.id);
                    }}
                  >
                    {tag.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
