import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import {
  Breadcrumb,
  Button,
  Checkbox,
  Form,
  // Image,
  Input,
  Layout,
  notification,
  Popconfirm,
  Space,
  Spin,
  Switch,
  Table,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  deleteItem,
  // getImageProductPreview,
  getProducts,
  getProductsById,
  getProductTags,
  setProductStatus,
  updateItem,
  updateProductStatus,
  updateProductTag,
} from '../../../redux/services/productSlice';
import { productApi } from '../../../api';
import ProductModal from '../../../modules/admin/productModal';
import { EditProduct } from '../editProduct';
import { getLocalStorage } from '../../../utils/localStorage';

interface DataTypeItem {
  key: string;
  name: string;
  description: string;
}

export const Setting = () => {
  const role_user: any = getLocalStorage('role_user');
  const { product_id } = useParams();
  const [idParam, setIdParam] = useState(product_id);
  const [api, showPopup] = notification.useNotification();
  const [editingRowItem, setEditingRowItem] = useState(null);
  const [dataSourceItem, setDataSourceItem] = useState<DataTypeItem[]>([]);
  const [fromItem] = Form.useForm();
  const [fromItemSetting] = Form.useForm();
  const [formNumberLimit] = Form.useForm();

  const {
    dataProductById,
    productName,
    listItem,
    listStyleTag,
    listPlayTag,
    listPreferTag,
    listPositionTag,
    listSizeTag,
    listThrowTag,
    statusProduct,
    loading,

    defaultSettingStyle,
    defaultSettingPlay,
    defaultSettingPrefer,
    defaultSettingPosition,
    defaultSettingThrow,
    defaultSettingSize,
    loadingUpdateStatus,

    // imagePreview,
  } = useSelector((state: any) => state.productReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductTags());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProductsById(product_id));
    // dispatch(getImageProductPreview(product_id));
  }, [dispatch, product_id]);

  useEffect(() => {
    setDataSourceItem(listItem);
    // eslint-disable-next-line
  }, [listItem]);

  useEffect(() => {
    formNumberLimit.setFieldsValue({
      limit_color: dataProductById?.limit_color,
    });
  }, [dispatch, dataProductById]);

  const handleListProduct = async () => {
    dispatchEvent(getProducts());
  };

  const columnsItems: any = [
    {
      key: 'sort',
    },
    {
      title: '順',
      dataIndex: 'no',
      align: 'center',
      render: (data: any, record: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: 'Key',
      dataIndex: 'key',
      className: 'hidden-row',
      render: () => {
        return (
          <Form.Item name="key">
            <Input style={{ textAlign: 'center' }} />
          </Form.Item>
        );
      },
    },
    {
      title: '名前',
      dataIndex: 'name',
      align: 'center',
      render: (text: any, record: any) => {
        if (editingRowItem === record.key) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'データを入力して下さい。',
                },
              ]}
              style={{margin: 0}}
            >
              <Input style={{ textAlign: 'center' }} />
            </Form.Item>
          );
        } else {
          return (
            <span
              style={{
                color: record.name === 'データを入力して下さい。' ? '#d2d2d2' : '',
              }}
            >
              {record.name}
            </span>
          );
        }
      },
    },
    {
      title: '説明',
      dataIndex: 'description',
      align: 'center',
      width: 150,
      render: (text: any, record: any) => {
        if (editingRowItem === record.key) {
          return (
            <Form.Item
              name="description"
              rules={[
                {
                  max: 255,
                  message: 'Value should be less than 255 character',
                },
              ]}
              style={{margin: 0}}
            >
              <Input style={{ textAlign: 'center' }} />
            </Form.Item>
          );
        } else {
          return (
            <span
              style={{
                color: record.description === 'データを入力して下さい。' ? '#d2d2d2' : '',
              }}
            >
              {record.description}
            </span>
          );
        }
      },
    },
    {
      title: '有料オプション',
      dataIndex: 'is_limit_color',
      align: 'center',
      render: (_: any, record: any) => {
        if (editingRowItem === record.key) {
          return(
            <Form.Item
              name="is_limit_color"
              valuePropName="checked"
              style={{margin: 0}}
            >
              <Checkbox key={`active-money-${record.key}`} checked={!!record.is_limit_color} />
            </Form.Item>
          );
        } else {
          return <Checkbox key={`active-money-${record.key}`} checked={!!record.is_limit_color} disabled />;
        }
      }
    },
    {
      title: '操作',
      dataIndex: 'actions',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <Space>
            <Button
              danger
              disabled={editingRowItem !== record.key}
              type="primary"
              htmlType="submit"
              ghost
              icon={<CloseCircleOutlined />}
              title="Cancel"
              onClick={() => {
                setEditingRowItem(null);
              }}
            />
            <Button
              type="default"
              icon={<EditOutlined />}
              title="編集"
              onClick={() => {
                setEditingRowItem(record.key);
                fromItem.setFieldsValue({
                  key: record.key,
                  image_base64: record.image,
                  name: record.name,
                  description: record.description,
                  is_limit_color: record.is_limit_color
                });
              }}
            />
            <Button
              disabled={editingRowItem !== record.key}
              type="primary"
              htmlType="submit"
              ghost
              icon={<SaveOutlined />}
              title="Save"
            />
            <Popconfirm
              title="削除しますか？"
              okText="はい"
              cancelText="いいえ"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => {
                deleteRowItem(record.key);
              }}
            >
              <Button danger icon={<DeleteOutlined />} title="Delete"></Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  // Handle Drag Drop
  const Row = ({ children, ...props }: any) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
      id: props['data-row-key'],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
      transition,
      cursor: 'move',
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === 'sort') {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: 'none', cursor: 'move' }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  // Update Item List After Moving
  const handleListItemUpdate = async (dataUpdate: any) => {
    const item_ids: any = [];
    dataUpdate.forEach((item: any) => {
      item_ids.push(item.key);
    });
    try {
      const res: any = await productApi.updateItemIndex({
        product_id: product_id,
        item_ids: item_ids.toString(),
      });

      if (res.status === 'success') {
        alertSuccess('移動しました。');
      }
    } catch (err) {
      alertFail('移動に失敗しました。');
    }
  };

  // Handle Move Item
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSourceItem((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        handleListItemUpdate(arrayMove(previous, activeIndex, overIndex));
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  // Alert Success
  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: 'topRight',
      duration: 1,
    });
  };
  // Alert Fail
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };

  // Delete Row Items
  const deleteRowItem = async (idDelete: any) => {
    const rows = [...dataSourceItem];
    const handleRow = rows.filter((item) => item.key !== idDelete);
    setDataSourceItem(handleRow);

    try {
      const res = await dispatch(deleteItem(idDelete));
      if (res.payload.status === 'success') {
        alertSuccess('削除しました。');
        dispatch(getProducts());
      }
    } catch (err) {
      alertFail('削除に失敗しました。');
    }
  };

  // Submit Data Items
  const onFinishItem = async (values: any) => {
    const dataNew = dataSourceItem.map((item) =>
      item.key === values.key
        ? {
            key: values.key,
            name: values.name,
            description: values.description,
            status: 1,
            is_limit_color: values.is_limit_color ? 1 : 0
          }
        : item,
    );

    const dataEdit = {
      product_id: product_id,
      name: values.name,
      description: values.description || 'Description...',
      status: 1,
      is_limit_color: values.is_limit_color ? 1 : 0
    };

    try {
      const res = await dispatch(updateItem({ id: values.key, body: dataEdit }));

      if (res.payload.status) {
        setEditingRowItem(null);
        setDataSourceItem(dataNew);
        dispatch(getProducts());
        alertSuccess('保存しました。');
      }
    } catch (err) {
      alertFail('保存に失敗しました。');
    }
  };

  // Submit Data Setting
  const onFinish = async (values: any) => {
    const dataSubmit = {
      product_tag_style_ids: values.styleTag.toString(),
      product_tag_play_ids: values.playTag.toString(),
      product_tag_position_ids: values.positionTag.toString(),
      product_tag_prefer_ids: values.preferTag.toString(),
      product_tag_size_ids: values.size.toString(),
      status: 1,
      product_tag_throw_ids: values.throwTag.toString(),
    };

    try {
      const res = await dispatch(updateProductTag({ id: product_id, body: dataSubmit }));
      if (res.payload.status === 'success') {
        alertSuccess('保存しました。');
      }
    } catch (err) {
      alertFail('エラーがあります。');
    }
  };

  // Submit Number of Colors
  const onFinishNumberColors = async (values: any) => {
    const dataSubmit = {
      limit_color: Number(values.limit_color),
    };

    try {
      const res: any = await productApi.updateProduct({ id: product_id, body: dataSubmit });
      if (res.status === 'success') {
        alertSuccess('保存しました。');
        dispatch(getProducts());
      }
    } catch (err) {
      alertFail('エラーがあります。');
    }
  }

  // const [statusPreview, setStatusPreview] = useState<boolean>(false);

  // Convert array setting all name,id to label,value form ant
  const settingAllStyle = listStyleTag.map((item: any) =>
    item.hasOwnProperty('name') && item.hasOwnProperty('id')
      ? {
          label: item.name,
          value: item.id.toString(),
        }
      : '',
  );

  const settingAllPlay = listPlayTag.map((item: any) =>
    item.hasOwnProperty('name') && item.hasOwnProperty('id')
      ? {
          label: item.name,
          value: item.id.toString(),
        }
      : '',
  );
  const settingAllPrefer = listPreferTag.map((item: any) =>
    item.hasOwnProperty('name') && item.hasOwnProperty('id')
      ? {
          label: item.name,
          value: item.id.toString(),
        }
      : '',
  );
  const settingAllPosition = listPositionTag.map((item: any) =>
    item.hasOwnProperty('name') && item.hasOwnProperty('id')
      ? {
          label: item.name,
          value: item.id.toString(),
        }
      : '',
  );
  const settingAllThrow = listThrowTag.map((item: any) =>
    item.hasOwnProperty('name') && item.hasOwnProperty('id')
      ? {
          label: item.name,
          value: item.id.toString(),
        }
      : '',
  );
  const settingAllSize = listSizeTag.map((item: any) =>
    item.hasOwnProperty('name') && item.hasOwnProperty('id')
      ? {
          label: item.name,
          value: item.id.toString(),
        }
      : '',
  );

  const handlePublic = async (checked: boolean) => {
    try {
      const res: any = await dispatch(
        updateProductStatus({
          id: product_id,
          body: { status: checked ? 1 : 2 },
        }),
      );
      if (res.payload.status === 'success') {
        dispatch(setProductStatus(checked));
        alertSuccess('追加しました。');
      }
    } catch (err) {
      alertFail('追加に失敗しました。');
    }
  };

  useEffect(() => {
    const data = {
      styleTag: defaultSettingStyle,
      playTag: defaultSettingPlay,
      preferTag: defaultSettingPrefer,
      positionTag: defaultSettingPosition,
      throwTag: defaultSettingThrow,
      size: defaultSettingSize,
    };
    fromItemSetting.setFieldsValue(data);
    // eslint-disable-next-line
  }, [
    defaultSettingStyle,
    defaultSettingPlay,
    defaultSettingPrefer,
    defaultSettingPosition,
    defaultSettingThrow,
    defaultSettingSize,
    fromItemSetting,
  ]);

  // Call api get data setting tag
  useEffect(() => {
    setIdParam(product_id);
  }, [product_id]);

  // Call api get data active setting tag
  useEffect(() => {
    fromItemSetting.resetFields();
    // eslint-disable-next-line
  }, [product_id, idParam]);

  return (
    <Layout style={{ padding: '24px' }}>
      {showPopup}
      <p className="title-view">設定</p>
      <Breadcrumb style={{ margin: '16px 0' }} items={[{ title: productName }, { title: '設定' }]}></Breadcrumb>
      <Content className="site-layout-background content-wrap">
        <div style={{ width: '100%' }}>
          <Spin spinning={loadingUpdateStatus}>
            <Space
              align="center"
              size={40}
              style={{
                backgroundColor: '#fff',
                borderRadius: '8px',
                padding: '15px',
                marginBottom: '50px',
              }}
            >
              <Link to={`/preview-product/${Number(product_id)}`} target="_blank">
                <Button type="primary" icon={<EyeOutlined />}>
                  プレビュー
                </Button>
              </Link>
              {/* <div style={{ display: 'none' }}>
                <Image
                  preview={{
                    visible: statusPreview,
                    onVisibleChange: () => setStatusPreview(false),
                  }}
                  src={imagePreview.image}
                />
              </div> */}
              <Space align="center">
                <label>公開</label>
                <Switch
                  onChange={handlePublic}
                  key={product_id}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={statusProduct === 2 ? false : true}
                />
              </Space>
            </Space>
          </Spin>
        </div>

        {/* Edit Product */}
        <div className="setting-wrap" style={{ paddingTop: '2.5rem' }}>
          <EditProduct dataProductById={dataProductById} />
        </div>

        {/* List Settting */}
        {role_user ? (
          <Spin spinning={loading}>
            <div className="setting-wrap setting-update" style={{marginTop: 50, marginBottom: 50}}>
              <Form onFinish={onFinish} form={fromItemSetting}>
                <div className="setting-item d-flex-center ">
                  <p className="label-setting">スタイル（style-tag）</p>
                  {defaultSettingStyle.length > 0 && (
                    <Form.Item name="styleTag" key={product_id}>
                      <Checkbox.Group options={settingAllStyle} />
                    </Form.Item>
                  )}
                </div>
                <div className="setting-item d-flex-center">
                  <p className="label-setting">プレイ（play-tag）</p>
                  {defaultSettingPlay.length > 0 && (
                    <Form.Item name="playTag">
                      <Checkbox.Group options={settingAllPlay} />
                    </Form.Item>
                  )}
                </div>
                <div className="setting-item d-flex-center">
                  <p className="label-setting">モデル（prefer-tag）</p>
                  {defaultSettingPrefer.length > 0 && (
                    <Form.Item name="preferTag">
                      <Checkbox.Group options={settingAllPrefer} />
                    </Form.Item>
                  )}
                </div>
                <div className="setting-item d-flex-center">
                  <p className="label-setting">ポジション（position-tag）</p>
                  {defaultSettingPosition.length > 0 && (
                    <Form.Item name="positionTag" initialValue={defaultSettingPosition}>
                      <Checkbox.Group options={settingAllPosition} />
                    </Form.Item>
                  )}
                </div>
                <div className="setting-item d-flex-center">
                  <p className="label-setting">利き腕（throw-tag）</p>
                  {defaultSettingThrow.length > 0 && (
                    <Form.Item name="throwTag">
                      <Checkbox.Group options={settingAllThrow} />
                    </Form.Item>
                  )}
                </div>
                <div className="setting-item d-flex-center">
                  <p className="label-setting">適応サイズ（size）</p>
                  {defaultSettingSize.length > 0 && (
                    <Form.Item name="size">
                      <Checkbox.Group options={settingAllSize} />
                    </Form.Item>
                  )}
                </div>
                <div className="setting-item">
                  <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} size="large">
                      保存
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Spin>
        ) : (
          ''
        )}

        {/* Number of Colors */}
        <Spin spinning={loading}>
          <div style={{marginBottom: 50, padding: '1.5rem', borderRadius: '8px', backgroundColor: '#fff'}}>
            <Form
              name="basic"
              onFinish={onFinishNumberColors}
              autoComplete="off"
              form={formNumberLimit}
              labelAlign={'left'}
              initialValues={{ remember: true }}
              style={{display: 'flex'}}
            >
              <Form.Item label="無料で選択できるカラー数：" name="limit_color" style={{margin: 0}} rules={[{ required: true, message: 'データを入力して下さい。' }]}>
                <Input type='number' style={{width: 250}} />
              </Form.Item>
              <Form.Item style={{ marginLeft: 25, marginBottom: 0}}>
                <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                  保存
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>

        {/* Table List Item */}
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext items={dataSourceItem.map((i: any) => i.key)} strategy={verticalListSortingStrategy}>
            <div className="table-wrap">
              <Form form={fromItem} onFinish={onFinishItem}>
                <Table
                  components={{
                    body: {
                      row: Row,
                    },
                  }}
                  rowKey="key"
                  columns={columnsItems}
                  dataSource={dataSourceItem}
                  className="text-center"
                  pagination={false}
                  title={() => (
                    <p>
                      リスト項目
                      <ProductModal
                        text="d"
                        productId={product_id}
                        dataSourceItem={dataSourceItem}
                        handleListProduct={handleListProduct}
                      />
                    </p>
                  )}
                />
              </Form>
            </div>
          </SortableContext>
        </DndContext>
      </Content>
    </Layout>
  );
};
