import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { DownloadOutlined } from '@ant-design/icons';
import { Link, useSearchParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Button, Layout, Table, Space, Pagination, notification } from 'antd';

import { getOrdersWithPagination } from '../../../redux/services/orderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { DataType } from '../../../types';
import { orderApi } from '../../../api';

const { Content } = Layout;

export const SelectedProduct = () => {
  let [searchParams, setSearchParams] = useSearchParams({
    page_index: '1',
    page_size: '10',
  });
  const { ordersWithPagination, loadingOrder } = useSelector((state: any) => state.orderReducer);
  const [dataOrders, setDataOrders] = useState<any>([]);
  const [idOrders, setIdOrders] = useState<number>();
  const [api, showPopup] = notification.useNotification();
  const location = window.location.host;
  const dispatch = useDispatch();
  const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  // Alert
  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: 'topRight',
      duration: 1,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };

  useEffect(() => {
    dispatch(
      getOrdersWithPagination({
        page_index: searchParams.get('page_index'),
        page_size: searchParams.get('page_size'),
      }),
    );

    if (searchParams.get('page_index') === null || searchParams.get('page_size') === null) {
      setSearchParams({ page_index: '1', page_size: '10' });
    }
    // eslint-disable-next-line
  }, [dispatch, searchParams]);

  const getColumnSearchProps = (dataIndex: string) => ({
    render: (text: any, record: any, index: any) => {
      if (dataIndex === 'datetime') {
        return record?.created_at ? (
          <span>{moment(record?.created_at).format('YYYY.MM/DD HH:mm')}</span>
        ) : (
          <span></span>
        );
      }
    },
  });

  const getDataOrders = async (id: number) => {
    try {
      const res: any = await orderApi.getOrdersById(id);
      if (res.status === 'success') {
        const temp: any[] = [];
        res.data.item_style_color?.forEach((data: any) => {
          if (data?.sub_item_style_color.length && data?.text_style_color.length) {
            temp.push([data.item, `スタイル : ${data.style}`, `色 : ${data.color}`]);
            data?.sub_item_style_color.forEach((item: any) => {
              temp.push(['_', `サブスタイル : ${item.style}`, `色 : ${item.color}`]);
            });
            data?.text_style_color.forEach((text: any) => {
              temp.push([
                '_',
                `テキストスタイル : ${text.name}`,
                `コンテンツ : ${text.text_value ? text.text_value : 'データはインプットの所をまだ入力しません'}`,
              ]);
            });
          } else if (data?.sub_item_style_color.length) {
            temp.push([data.item, `スタイル : ${data.style}`, `色 : ${data.color}`]);
            data?.sub_item_style_color.forEach((item: any) => {
              temp.push(['_', `サブスタイル : ${item.style}`, `色 : ${item.color}`]);
            });
          } else if (data?.text_style_color.length) {
            temp.push([data.item, `スタイル : ${data.style}`, `色 : ${data.color}`]);
            data?.text_style_color.forEach((text: any) => {
              temp.push([
                '_',
                `テキストスタイル : ${text.name}`,
                `コンテンツ : ${text.text_value ? text.text_value : 'データはインプットの所をまだ入力しません'}`,
              ]);
            });
          } else {
            temp.push([data.item, `スタイル : ${data.style}`, `色 : ${data.color}`]);
          }
        });

        setIdOrders(res.data.id);
        setDataOrders([
          ["ID Orders", res.data.id],
          ["モデル名", res.data.product],
          ["スタイル", res.data.product_tag_styles.name],
          ["プレイ", res.data.product_tag_plays.name],
          ["モデル", res.data.product_tag_prefers.name],
          ["ポジション", res.data.product_tag_positions.name],
          ["利き腕", res.data.product_tag_throws.name],
          ["適応サイズ", '=""' + res.data.product_tag_sizes.name + '""'],
          ...temp,
        ]);
        alertSuccess('Download Success!');
        setTimeout(() => {
          csvLink.current?.link.click();
        }, 100);
      }
    } catch (err) {
      alertFail('Download Fail!');
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
      key: 'id',
    },
    {
      title: '日時',
      dataIndex: 'datetime',
      align: 'center',
      key: 'datetime',
      ...getColumnSearchProps('datetime'),
    },
    {
      title: '公開URL',
      dataIndex: 'URL',
      align: 'center',
      key: 'url',
      render: (text, record, index) => (
        <Space>
          <Link to={`/overview/${record?.id}`} target="_blank">{`${location}/overview/${record?.id}`}</Link>
        </Space>
      ),
    },
    {
      title: '活動',
      key: 'action',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type="primary" icon={<DownloadOutlined />} size={'large'} onClick={() => getDataOrders(record?.id)}>
            Download
          </Button>
          <CSVLink
            data={dataOrders}
            filename={`order-rawlings-${idOrders}.csv`}
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        </Space>
      ),
    },
  ];

  const handlePagination = async (page: number, pageSize: number) => {
    setSearchParams({ page_index: page.toString(), page_size: pageSize.toString() }, { replace: true });
  };

  return (
    <Layout className="style-color" style={{ padding: '24px' }}>
      {showPopup}
      <Content className="site-layout-background content-wrap">
        <div style={{ fontSize: '25px', marginBottom: '15px' }}>選択された商品</div>
        <Table
          rowKey="id"
          loading={loadingOrder}
          columns={columns}
          dataSource={ordersWithPagination?.data}
          pagination={false}
        />
        <Space style={{ width: '100%', marginTop: '2rem' }} direction="vertical" align="end">
          <Pagination
            onChange={handlePagination}
            current={Number(searchParams.get('page_index'))}
            pageSize={Number(searchParams.get('page_size'))}
            total={ordersWithPagination?.total}
            pageSizeOptions={[5, 10, 15, 20, 25, 30]}
            showSizeChanger
          />
        </Space>
      </Content>
    </Layout>
  );
};
