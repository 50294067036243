import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Modal, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addItem, getProducts } from '../../../redux/services/productSlice';
import { getLocalStorage } from '../../../utils/localStorage';
import { useSelector } from 'react-redux';

const ProductModal = ({ productId, dataSourceItem, text }: any) => {
  const role_user: any = getLocalStorage('role_user');
  const { listItem } = useSelector((state: any) => state.productReducer);

  const [dataInputAdd, setDataInputAdd] = useState('');
  const [dataDescription, setDataDescription] = useState('');
  const [limitColor, setLimitColor] = useState<boolean>(false);
  const [formProduct] = Form.useForm();
  const dispatch = useDispatch();

  const [api, showPopup] = notification.useNotification();
  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: 'topRight',
      duration: 1,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };

  const onFinish = async (productId: any) => {
    const dataAddItem = {
      name: dataInputAdd,
      product_id: productId,
      description: dataDescription,
      required: 0,
      is_limit_color: limitColor ? 1 : 0
    };
    try {
      const res = await dispatch(addItem(dataAddItem));
      if (res.payload.status === 'success') {
        alertSuccess('追加しました。');
        dispatch(getProducts());
        setDataInputAdd('');
      }
    } catch (err) {
      alertFail('追加に失敗しました。');
    }

    // TODO: add logic to handle adding the item
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    formProduct
      .validateFields()
      .then(() => {
        // Form is valid, so submit data and reset form
        onFinish(productId);
        formProduct.resetFields();
        setIsModalOpen(false);
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {showPopup}
      {text ? (
        <Button
          disabled={Number(role_user) === 0 && listItem?.data?.length > 2 ? true : false}
          onClick={showModal}
          type="primary"
          icon={<PlusCircleOutlined />}
        >
          {' '}
          アイテムを追加
        </Button>
      ) : (
        <div onClick={showModal}>アイテムを追加</div>
      )}
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText="追加" cancelText="キャンセル">
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '20px',
            fontWeight: '500',
          }}
        >
          アイテムを追加
        </span>

        <Form
          form={formProduct}
          onFinish={onFinish}
          labelCol={{ span: 4 }}
          style={{ maxWidth: 600, marginTop: '10px' }}
          name="addProduct"
          autoComplete="off"
          layout="horizontal"
          scrollToFirstError
        >
          <Form.Item
            name="name"
            label="名前"
            rules={[
              {
                required: true,
                message: 'Please input data!',
              },
            ]}
          >
            <Input
              value={dataInputAdd}
              onChange={(e) => {
                setDataInputAdd(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            name="description"
            label="説明"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please input data!',
            //   },
            // ]}
          >
            <TextArea
              value={dataDescription}
              onChange={(e) => {
                setDataDescription(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            name="is_limit_color"
            label="有料"
            valuePropName="checked"
            style={{marginTop: '-10px'}}
          >
            <Checkbox onChange={(e) => {setLimitColor(e.target.checked)}}/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ProductModal;
