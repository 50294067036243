const path_URL: string = window.location.hostname;

const checkPath = (path_URL: string) => {
  switch (path_URL) {
    // FORTUNA
    case 'colorsimulation.com':
      return '221025';

    // STG FORTUNA
    case 'stg.colorsimulation.com':
      return '221025';

    // RAWLINGS
    case 'rawlings.colorsimulation.com':
      return '230630';

    // STG RAWLINGS
    case 'stg-rawlings.colorsimulation.com':
      return '230630';

    // DEUX
    case 'deuxfoyer.colorsimulation.com':
      return '230513';

    // STG DEUX
    case 'stg-deuxfoyer.colorsimulation.com':
      return '230513';

    // ISOL
    case 'isol.colorsimulation.com':
      return '230530';

    // STG ISOL
    case 'stg-isol.colorsimulation.com':
      return '230530';

    // AKISHIMA
    case 'akishima-ryohin.colorsimulation.com':
      return '231107';

    // COLOR_SIMULATION_DEMO
    case 'demo.colorsimulation.com':
      return '231204';

    // LOCAL
    case 'localhost':
      return '230101';
  }
};

const patchDynamic = checkPath(path_URL);

export default patchDynamic;
