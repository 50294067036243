import { useEffect, useState } from 'react';
import { productApi } from '../../../api';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../../../utils/localStorage';
import { useNavigate } from 'react-router-dom';
import routes from '../../../config/routes';

export const Home = () => {
  const navigate = useNavigate();
  const [roleUser, setRoleUser] = useState<number>(0);

  const productDetail: any = getLocalStorage('productDetail');
  const dataOrdersLocal: any = getLocalStorage('data_orders');

  const [checkExistProduct, setCheckExistProduct] = useState(false);
  const listKeyRemove = [
    'activeTag',
    'productTag',
    'arrayActive',
    'data_orders',
    'productDetail',
    'styleColorTextActive',
    'listDataText',
    'fontIdActive',
    'priceStyle',
    'priceSubStyle',
    'priceAll',
    'totalMoneyText'
  ];

  // Get Product.
  const getProduct = async () => {
    try {
      const resData: any = await productApi.getCustomerAllProduct();
      if (resData.status === 'success') {
        const tempData = resData.data;
        setLocalStorage('productDetail', tempData[0]);
        navigate(routes.chooseColors);
      }
    } catch (err) {
      console.log('削除に失敗しました。');
    }
  };

  // Get Role User.
  const getRole = async () => {
    try {
      const resData: any = await productApi.getCustomerRole();
      if (resData.status === 'success') {
        const role_user = resData.data.role;
        setRoleUser(role_user);
        if (!role_user) {
          getProduct();
        }
      }
    } catch (err) {
      console.log('Fail.');
    }
  };

  const handleRemoveListKey = () => {
    listKeyRemove.forEach((key: string) => {
      removeLocalStorage(key);
    });
  };

  const checkProductPublic = async () => {
    try {
      const res: any = await productApi.getCustomerProductsById(productDetail?.id);
      if (res.status === 'success') {
        setCheckExistProduct(true);
      } else {
        setCheckExistProduct(false);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (productDetail?.id) {
      checkProductPublic();
    }
  });

  useEffect(() => {
    // Get Role
    getRole();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {roleUser ? (
        <section className="main screen_bg">
          <div className="container">
            <div className="flex-center">
              <div className="bg_main">
                <h1 className="title_main">CUSTOM GLOVES</h1>
                <h2 className="suptitle_main">自分だけのカスタムグラブを作成</h2>
                {/* <div className="block_button_main"> */}
                <div className="list-btn-home">
                  <a href="/terms-of-use-1" onClick={handleRemoveListKey}>
                    <button className="btn_main btn_design">デザインする</button>
                  </a>
                  <a
                    href="/choose-colors"
                    style={
                      checkExistProduct && dataOrdersLocal?.getStyleColorImageByStyleColorId?.length
                        ? {}
                        : { pointerEvents: 'none', opacity: '0.5' }
                    }
                  >
                    <button className="btn_main btn_resume">再開</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <section className="footer_screen">
            <div className="warning">
              <p>高校野球の制限について</p>
              <p>取扱販売店はこちら</p>
              <div className="wrap-text-end">
                <h4>
                  THE GOLD GLOVE CO.<span>TM</span>
                </h4>
                <h5>Copyright © Rawlings Japan LLC All Rights Reserved.</h5>
              </div>
            </div>
          </section>
        </section>
      ) : (
        ''
      )}
    </>
  );
};
