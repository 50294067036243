export const TermUse1 = () => {
  return (
    <section className="main screen_txt">
      <div className="container">
        <div className="txt_main">
          <h1>ご利用にあたって</h1>
          <div className="block_txt">
            <h2>ご利用上の注意</h2>
            <p>
              ※システムの不具合、または、お客様のPC、スマートフォン、タブレット等の環境により、イラストが正しく表示されない、合計金額が正しく表示されない、その他不具合が発生する場合がございます。
            </p>
            <p>
              このサービスはネットワーク通信を致しますので、通信に問題がない環境（Wi−Fi環境など）でのご利用をおすすめしております。
              回線が重い場合、正しいデータを送信できず、適切に表示などができない可能性がございますので、ご注意ください。
            </p>
            <ul>
              <li>＊商品の色はディスプレイの表示上、現物と多少異なる場合がありますのであらかじめご了承ください。</li>
              <li>
                ＊表示されるのは、あくまでイメージになります。パーツの形状など、現物とは異なる場合がありますので、あらかじめご了承ください。
              </li>
              <li>＊高校野球など、連盟ごとに使用制限がありますので各自ご確認下さい。</li>
              <li>
                ＊本システムで表示されない追記事項やご要望がある場合は作成されたオーダーシートの特記事項に手書きでご記入ください。
              </li>
            </ul>
          </div>
          <div className="block_txt">
            <h2>ご注文方法</h2>
            <p>作成された内容を印刷してお近くのRAWLINGS ORDER GLOVE取扱販売店様までお持ちください。</p>
            <p>
              販売店様よりFAX及びメールにてご発注後、弊社にてオーダー内容に間違いがないか確認させて頂きます。
              その後、弊社から販売店様への最終オーダー確認をもって、オーダー確定（納期のご回答）とさせて頂きますのであらかじめご了承下さい。
              回線が重い場合、正しいデータを送信できず、適切に表示などができない可能性がございますので、ご注意ください。
            </p>
            <p>
              店頭にてご発注いただきますと変更及びキャンセルは致しかねますので、あらかじめご了承の上、ご発注ください。
            </p>
          </div>
          <div className="block_button_main flex-center mt-50">
            <a href="/terms-of-use-2">
              <button className="btn_main btn_accept">承諾する</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
