import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { productApi } from '../../api';
import patchDynamic from '../../helper/pathDynamic';

export const getProducts: any = createAsyncThunk('product/getProducts', async () => {
  try {
    const res = await productApi.getProducts();
    return res.data;
  } catch (error: any) {
    if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
      window.location.replace(`${window.location.origin}/login-${patchDynamic}`);
    }
  }
});

export const getProductsWithSearch: any = createAsyncThunk('product/getProductsWithSearch', async (data) => {
  try {
    const res = await productApi.getProductsWithSearch(data);
    return res;
  } catch (error: any) {
    if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
      window.location.replace(`${window.location.origin}/login-${patchDynamic}`);
    }
  }
});

export const getProductsById: any = createAsyncThunk('product/getProductsById', async (id) => {
  try {
    const res = await productApi.getProductsById(id);
    return res.data;
  } catch (error: any) {
    if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
      window.location.replace(`${window.location.origin}/login-${patchDynamic}`);
    }
  }
});

export const getProductTags: any = createAsyncThunk('product/getProductTags', async () => {
  try {
    const res = await productApi.getProductTags();
    return res.data;
  } catch (error: any) {
    if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
      window.location.replace(`${window.location.origin}/login-${patchDynamic}`);
    }
  }
});

export const deleteProduct: any = createAsyncThunk('product/deleteProduct', async (id: any) => {
  try {
    const res = await productApi.delete(id);
    return {
      response: res.data,
      id: id,
    };
  } catch (error: any) {
    if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
      window.location.replace(`${window.location.origin}/login-${patchDynamic}`);
    }
  }
});

export const updateProductTag: any = createAsyncThunk('/product/updateProductTag', async (data) => {
  const res = await productApi.updateProductTag(data);
  return res;
});

export const addItem: any = createAsyncThunk('/product/addItem', async (data: any, thunkAPI) => {
  const res: any = await productApi.addItem(data);
  if (res.status === 'success') {
    thunkAPI.dispatch(getProductsById(data.product_id));
    return res;
  }
});

export const deleteItem: any = createAsyncThunk('/product/deleteItem', async (id: any) => {
  const res = await productApi.deleteItemProduct(id);
  return res;
});

export const updateItem: any = createAsyncThunk('/product/updateItem', async (data) => {
  const res = await productApi.updateItem(data);
  return res;
});

// export const getImageProductPreview: any = createAsyncThunk('/product/getImagePreview', async (id) => {
//   const res = await productApi.getImagePreview(id);
//   return res.data;
// });

export const updateProductStatus: any = createAsyncThunk('/product/updateProductStatus', async (id) => {
  const res = await productApi.updateProductStatus(id);
  return res;
});

const productSlice = createSlice({
  name: 'product',
  initialState: {
    products: [],
    statusProduct: 2,
    dataProductById: {},
    productName: '',
    listItem: [],
    listStyleTag: [],
    listPlayTag: [],
    listPreferTag: [],
    listPositionTag: [],
    listSizeTag: [],
    listThrowTag: [],

    //
    defaultSettingStyle: [],
    defaultSettingPlay: [],
    defaultSettingPrefer: [],
    defaultSettingPosition: [],
    defaultSettingThrow: [],
    defaultSettingSize: [],

    // imagePreview: '',

    loading: false,
    loadingUpdateStatus: false,
  },
  reducers: {
    setProductStatus(state: any, action) {
      state.statusProduct = action.payload ? 1 : 2;
    },
  },
  extraReducers: {
    [getProducts.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [getProducts.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [getProducts.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.products = action.payload;
    },

    [getProductsWithSearch.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [getProductsWithSearch.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [getProductsWithSearch.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.productsWithSearch = action.payload.data;
    },

    [deleteProduct.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [deleteProduct.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [deleteProduct.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      const tmpProductsWithSearch = current(state.productsWithSearch)?.data?.filter(
        (item: any) => item.id !== action.payload.id,
      );
      const tmpProducts = current(state.products)?.filter((item: any) => item.id !== action.payload.id);
      state.productsWithSearch = {
        ...current(state.productsWithSearch),
        data: tmpProductsWithSearch,
      };
      state.products = tmpProducts;
    },

    [getProductTags.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [getProductTags.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [getProductTags.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.listStyleTag = action.payload.product_tag_style.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
      state.listPlayTag = action.payload.product_tag_play.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
      state.listPreferTag = action.payload.product_tag_prefer.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
      state.listPositionTag = action.payload.product_tag_position.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
      state.listThrowTag = action.payload.product_tag_throw.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
      state.listSizeTag = action.payload.product_tag_size.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
    },

    [getProductsById.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [getProductsById.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [getProductsById.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.statusProduct = action.payload.status;
      state.productName = action.payload.name;
      state.dataProductById = action.payload;
      state.defaultSettingPlay = action.payload.product_tag_plays.map((item: any) => item.id.toString());

      state.defaultSettingPosition = action.payload.product_tag_positions.map((item: any) => item.id.toString());

      state.defaultSettingPrefer = action.payload.product_tag_prefers.map((item: any) => item.id.toString());

      state.defaultSettingSize = action.payload.product_tag_sizes.map((item: any) => item.id.toString());

      state.defaultSettingStyle = action.payload.product_tag_styles.map((item: any) => item.id.toString());

      state.defaultSettingThrow = action.payload.product_tag_throws.map((item: any) => item.id.toString());
      state.listItem = action.payload.items?.map((item: any) => {
        return {
          ...item,
          key: item?.id,
        };
      });
    },

    // GET IMAGE PREVIEW

    // [getImageProductPreview.pending]: (state: any, action: any) => {
    //   state.loading = true;
    // },
    // [getImageProductPreview.reject]: (state: any, action: any) => {
    //   state.loading = false;
    // },
    // [getImageProductPreview.fulfilled]: (state: any, action: any) => {
    //   state.loading = false;
    //   state.imagePreview = action.payload;
    // },

    [updateProductStatus.pending]: (state: any, action: any) => {
      state.loadingUpdateStatus = true;
    },
    [updateProductStatus.reject]: (state: any, action: any) => {
      state.loadingUpdateStatus = false;
    },
    [updateProductStatus.fulfilled]: (state: any, action: any) => {
      state.loadingUpdateStatus = false;
    },
  },
});

export const { setProductStatus } = productSlice.actions;

const { reducer: productReducer } = productSlice;

export default productReducer;
