import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Breadcrumb, Layout, Input, Form, Upload, Spin, notification, Checkbox, InputNumber } from 'antd';
import { getProducts, getProductTags } from '../../../redux/services/productSlice';
import { useSelector } from 'react-redux';
import { productApi } from '../../../api';
import { getLocalStorage } from '../../../utils/localStorage';

const { Content } = Layout;
const { TextArea } = Input;

export const AddProduct = () => {
  const role_user: any = getLocalStorage('role_user');
  const [form] = Form.useForm();
  const { products } = useSelector((state: any) => state.productReducer);
  const [api, showPopup] = notification.useNotification();
  const dispatch = useDispatch();
  const { listStyleTag, listPlayTag, listPreferTag, listPositionTag, listSizeTag, listThrowTag } = useSelector(
    (state: any) => state.productReducer,
  );

  // Alert
  const alertLoginSuccess = (message: string) => {
    api.success({
      message,
      placement: 'topRight',
      duration: 1,
    });
  };
  const alertLoginFail = (message: string) => {
    api.error({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };

  // Handle Submit
  const onFinish = async (values: any) => {
    const dataSubmit = role_user
      ? {
          name: values.name,
          price: values.price,
          image_base64: values.image_base64[0].thumbUrl,
          description: values.description,
          product_tag_style_ids: values.styleTag ? values.styleTag.toString() : '1',
          product_tag_play_ids: values.playTag ? values.playTag.toString() : '1',
          product_tag_position_ids: values.positionTag ? values.positionTag.toString() : '1',
          product_tag_prefer_ids: values.preferTag ? values.preferTag.toString() : '1',
          product_tag_size_ids: values.size ? values.size.toString() : '1',
          product_tag_throw_ids: values.throwTag ? values.throwTag.toString() : '1',
        }
      : {
          name: values.name,
          price: values.price,
          image_base64: values.image_base64[0].thumbUrl,
          description: values.description,
        };
    try {
      const res: any = await productApi.create(dataSubmit);
      if (res.status === 'success') {
        alertLoginSuccess('追加しました。');
        dispatch(getProducts());
        form.setFieldsValue({
          name: '',
          price: '',
          image_base64: [],
          description: '',
        });
      }
    } catch (err) {
      alertLoginFail('追加に失敗しました。');
    }
  };

  const valueFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  useEffect(() => {
    dispatch(getProductTags());
    // eslint-disable-next-line
  }, []);

  return (
    <Layout style={{ padding: '24px' }}>
      {showPopup}
      <p className="title-view">商品を追加</p>
      <Breadcrumb style={{ margin: '16px 0' }} items={[{ title: '商品' }, { title: '商品を追加' }]}></Breadcrumb>
      <Content className="site-layout-background" style={{ padding: '60px 0', margin: 0 }}>
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item label="商品名" name="name" rules={[{ required: true, message: '商品名を入力してください。' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="価格" name="price" rules={[{ required: true, message: '商品名を入力してください。' }]}>
            <InputNumber type="number" min={0} />
          </Form.Item>
          <Form.Item
            name="image_base64"
            label="商品画像"
            valuePropName="fileList"
            getValueFromEvent={valueFile}
            rules={[{ required: true, message: '商品画像を入力してください。' }]}
          >
            <Upload
              listType="picture"
              maxCount={1}
              iconRender={() => {
                return <Spin></Spin>;
              }}
              beforeUpload={() => {
                return false;
              }}
            >
              <Button icon={<UploadOutlined />}>画像をアップロード</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="説明	" name="description" rules={[{ required: true, message: '説明を入力してください。' }]}>
            <TextArea showCount maxLength={999} />
          </Form.Item>

          {/* Select Tags */}
          {role_user ? (
            <div className="setting-wrap mb-25">
              <div className="setting-item">
                <Form.Item name="styleTag">
                  <Checkbox.Group>
                    <p>スタイル（style-tag）</p>
                    {listStyleTag.map(function (listStyleTagItem: any, data: any) {
                      return (
                        <Checkbox key={listStyleTagItem.id} value={listStyleTagItem.id}>
                          {listStyleTagItem.name}
                        </Checkbox>
                      );
                    })}
                  </Checkbox.Group>
                </Form.Item>
              </div>
              <div className="setting-item">
                <Form.Item name="playTag">
                  <Checkbox.Group>
                    <p>プレイ（play-tag）</p>
                    {listPlayTag.map(function (listPlayTagItem: any, data: any) {
                      return (
                        <Checkbox key={listPlayTagItem.id} value={listPlayTagItem.id}>
                          {listPlayTagItem.name}
                        </Checkbox>
                      );
                    })}
                  </Checkbox.Group>
                </Form.Item>
              </div>
              <div className="setting-item">
                <Form.Item name="preferTag">
                  <Checkbox.Group>
                    <p>モデル（prefer-tag）</p>
                    {listPreferTag.map(function (listPreferTagItem: any, data: any) {
                      return (
                        <Checkbox key={listPreferTagItem.id} value={listPreferTagItem.id}>
                          {listPreferTagItem.name}
                        </Checkbox>
                      );
                    })}
                  </Checkbox.Group>
                </Form.Item>
              </div>
              <div className="setting-item">
                <Form.Item name="positionTag">
                  <Checkbox.Group>
                    <p>ポジション（position-tag）</p>
                    {listPositionTag.map(function (listPositionTagItem: any, data: any) {
                      return (
                        <Checkbox key={listPositionTagItem.id} value={listPositionTagItem.id}>
                          {listPositionTagItem.name}
                        </Checkbox>
                      );
                    })}
                  </Checkbox.Group>
                </Form.Item>
              </div>
              <div className="setting-item">
                <Form.Item name="throwTag">
                  <Checkbox.Group>
                    <p>利き腕（throw-tag）</p>
                    {listThrowTag.map(function (listThrowTagItem: any, data: any) {
                      return (
                        <Checkbox key={listThrowTagItem.id} value={listThrowTagItem.id}>
                          {listThrowTagItem.name}
                        </Checkbox>
                      );
                    })}
                  </Checkbox.Group>
                </Form.Item>
              </div>
              <div className="setting-item">
                <Form.Item name="size">
                  <Checkbox.Group>
                    <p>適応サイズ（size）</p>
                    {listSizeTag.map(function (listSizeTagItem: any, data: any) {
                      return (
                        <Checkbox key={listSizeTagItem.id} value={listSizeTagItem.id}>
                          {listSizeTagItem.name}
                        </Checkbox>
                      );
                    })}
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </div>
          ) : (
            ''
          )}
          {/* End Select Tags */}

          <Form.Item wrapperCol={{ offset: 10 }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={Number(role_user) === 0 && products?.length > 1 ? true : false}
            >
              商品を追加
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};
