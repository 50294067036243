// import { useNavigate } from 'react-router-dom';
// import { productApi } from '../../../api';
// import { setLocalStorage } from '../../../utils/localStorage';
// import routes from '../../../config/routes';
// import { useEffect, useState } from 'react';

export const TermUse2 = () => {
  // const navigate = useNavigate();
  // const [roleUser, setRoleUser] = useState<number>(0);

  // Get Product.
  // const getProduct = async () => {
  //   try {
  //     const resData: any = await productApi.getCustomerAllProduct();
  //     if (resData.status === 'success') {
  //       const tempData = resData.data;
  //       setLocalStorage('productDetail', tempData[0]);
  //       navigate(routes.chooseColors);
  //     }
  //   } catch (err) {
  //     console.log('削除に失敗しました。');
  //   }
  // };

  // Get Role User.
  // const getRole = async () => {
  //   try {
  //     const resData: any = await productApi.getCustomerRole();
  //     if (resData.status === 'success') {
  //       setRoleUser(resData.data.role);
  //     }
  //   } catch (err) {
  //     console.log('Fail.');
  //   }
  // };

  // useEffect(() => {
  //   getRole();
  // }, []);
  return (
    <section className="main screen_txt">
      <div className="container">
        <div className="txt_main">
          <h1>高校野球用具の使用制限について</h1>
          <p className="mt-45 bold">
            高校野球ではグラブ・ミットについて以下の使用制限があります。下記の内容をご確認の上、ご発注の際にはご注意願います。
          </p>
          <div className="block_txt">
            <h2>マークの表記〈商標〉</h2>
            <p>その大きさは縦４センチ、横７センチ以内とする。</p>
            <p>
              商標の材質は、布片に刺繍または樹脂の成型物のほか、連盟が認めたものとする。
              回線が重い場合、正しいデータを送信できず、適切に表示などができない可能性がございますので、ご注意ください。
            </p>
            <p>表記箇所は、背帯あるいは背帯に近い部分、または親指のつけ根の部分のうちいずれか１箇所とする。</p>
            <p>
              投手用グラブに商標を表示する場合、その色は文字の部分を含み、すべて白または灰色以外の色でなければならない。
            </p>
          </div>
          <div className="block_txt">
            <h2>カラー</h2>
            <p>カラー※投手用、捕手用、野手用　共通</p>
            <p>
              本体カラーは、ブラウン系、オレンジ系、ブラックとする。また使用できるカラーであれば、表部と裏部（平裏）部のカラーが違っていても使用可とする。
            </p>
            <p>
              品名・品番・マーク類などをスタンプによって表示する場合の色は、ブラックまたは焼印の自然色でなければならない。
            </p>
            <p>縫い糸</p>
            <p>特にカラー制限を定めない。</p>
            <p>ハミ出し</p>
            <p>グラブ本体と同系色で目立たないもの、または革の自然色とする。</p>
            <p>しめひも</p>
            <p>
              投手用グラブのしめひもは、グラブ本体と同色でなければならない。ただし、グラブ本体と同系色で目立たないものについては差し支えない。
            </p>
            <p>
              野手用（捕手含む）のしめひもは、本体カラーと同系色とする。ただし、ブラックとブラウン系のしめひもに限っては本体カラーにかかわらず使用できる。
            </p>
          </div>
          <div className="block_txt">
            <h2>その他</h2>
            <p>グラブ、ミットの表面（捕球面・背面）に氏名、番号、その他の文字を表記することを禁止する。</p>
            <p>
              捕球面と背面が同一カラーであれば、革の表面への処理（スムース加工やシボ加工、パンチング加工）の違いは使用を認める。
            </p>
            <p>しめひもは、長すぎないこと。親指の長さ程度にすること。</p>
            <p>（日本高等学校野球連盟用具使用制限より一部抜粋）</p>
          </div>
          <div className="block_button_main flex-center mt-50">
            {/* {roleUser === 0 ? (
              <a onClick={getProduct}>
                <button className="btn_main btn_accept">閉じる</button>
              </a>
            ) : (
              <a href="/choose-options">
                <button className="btn_main btn_accept">閉じる</button>
              </a>
            )} */}
            <a href="/choose-options">
              <button className="btn_main btn_accept">閉じる</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
