import { ListTags } from '../../types/tags';
import axiosClient from '../axiosClient';

export const productApi = {
  async getProducts() {
    const url = '/products';
    return axiosClient.get(url);
  },

  async getProductsWithSearch(urlString: any) {
    const url = `/search-product?${urlString}`;
    return axiosClient.get(url);
  },

  async getProductTags() {
    const url = '/product-tags';
    return axiosClient.get(url);
  },

  async updateItemIndex(data: any) {
    const url = '/items/update-item-index';
    return axiosClient.post(url, data);
  },

  async getProductsById(id: any) {
    const url = `/products/${id}`;
    return axiosClient.get(url);
  },

  async getCustomerProductsById(id: any) {
    const url = `/customer/products/${id}`;
    return axiosClient.get(url);
  },

  async getPrivateProductById(id: any) {
    const url = `/products/preview-product/${id}`;
    return axiosClient.get(url);
  },

  async delete(id: any) {
    const url = `/products/${id}`;
    return axiosClient.delete(url);
  },

  async create(data: any) {
    const url = '/products';
    return axiosClient.post(url, data);
  },

  async getCustomerProduct(idProduct: number) {
    const url = `/customer/product-tags/${idProduct}`;
    return axiosClient.get(url);
  },

  async getCustomerAllProduct() {
    const url = `/customer/get-all-products`;
    return axiosClient.get(url);
  },

  async getCustomerRole() {
    const url = `/customer/role`;
    return axiosClient.get(url);
  },

  async getProductWithTags(data: ListTags) {
    const url = `customer/products?product_tag_style_id=${data.styleTag}
    &product_tag_play_id=${data.playTag}
    &product_tag_prefer_id=${data.preferTag}
    &product_tag_position_id=${data.positionTag}
    &product_tag_size_id=${data.sizeTag}
    &product_tag_throw_id=${data.throwTag}`;
    return axiosClient.get(url);
  },

  async getAllTags() {
    const url = 'customer/product-tags';
    return axiosClient.get(url);
  },

  async updateProductTag(data: any) {
    const url = `/products/update-tag-style/${data.id}`;
    return axiosClient.put(url, data.body);
  },

  async addItem(data: any) {
    const url = '/items';
    return axiosClient.post(url, data);
  },

  async deleteItemProduct(id: any) {
    const url = `/items/${id}`;
    return axiosClient.delete(url);
  },

  async updateItem(data: any) {
    const url = `/items/${data.id}`;
    return axiosClient.put(url, data.body);
  },

  async updateProductStatus(data: any) {
    const url = `/products/update-status/${data.id}`;
    return axiosClient.put(url, data.body);
  },

  // async getImagePreview(id: any) {
  //   const url = `/products/preview/${id}`;
  //   return axiosClient.get(url);
  // },

  async updateProduct(data: any) {
    const url = `products/${data.id}`;
    return axiosClient.put(url, data.body);
  },

  async getListFaceByProduct(id: any) {
    const url = `/customer/get-style-color-image-by-product/${id}`;
    return axiosClient.get(url);
  },
};
